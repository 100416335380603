import React, { FC, ReactNode, RefObject } from 'react';
import cx from 'classnames';
import { TReadMorePlacement } from './types';

type TReadMoreProps = {
  children: ReactNode;
  containerRef: RefObject<HTMLDivElement>;
  textRef: RefObject<HTMLParagraphElement>;
  showButton?: boolean;
  isExpanded?: boolean;
  onClick?: () => void;
  placement?: TReadMorePlacement;
};

export const ReadMore: FC<TReadMoreProps> = ({
  children,
  containerRef,
  textRef,
  showButton,
  isExpanded,
  onClick,
  placement = 'sidebar',
}) => (
  <div className="relative" ref={containerRef}>
    <p
      ref={textRef}
      className={cx('text-sm transition-all duration-300 ease-in-out', {
        'line-clamp-4': !isExpanded,
      })}
    >
      {children}
    </p>
    {showButton && (
      <div
        className={cx('group-hover/sidebar:bg-t2-shades-white absolute right-0 ml-1', {
          'bottom-0': !isExpanded,
          '-bottom-5': isExpanded,
          'bg-t2-neutral-grey01': placement === 'sidebar',
          'bg-t2-neutral-grey02': placement === 'territoryDiscovery',
        })}
      >
        <span
          className={cx('relative after:z-10 after:absolute after:bottom-0 after:-right-1 after:w-10 after:h-6', {
            'group-hover/sidebar:after:bg-input-gradient-right': !isExpanded,
            'after:bg-t2-neutral-grey01-input-gradient-right': placement === 'sidebar' && !isExpanded,
            'after:bg-t2-neutral-grey02-input-gradient-right': placement === 'territoryDiscovery' && !isExpanded,
          })}
        />
        <button
          onClick={onClick}
          className="text-sm text-t2-neutral-grey07 hover:text-t2-primary-brandColor focus:outline-none font-normal ml-1"
        >
          {isExpanded ? (
            <span className="capitalize underline">less</span>
          ) : (
            <span>
              ...<span className="capitalize underline">more</span>
            </span>
          )}
        </button>
      </div>
    )}
  </div>
);
