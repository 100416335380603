import React, { FC } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import uniqBy from 'lodash/uniqBy';
import { getLinkRelAttributeValue } from 'utils/getLinkRelAttributeValue';
import { TMember } from '../TerritoryMembersPopUp/MemberProfile';
import { StackAvatar } from '../StackAvatar/StackAvatar';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import ArticleInteractionsCopy from '../Article/ArticleInteractionsCopy';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';
import { T2ProfileCardContainer } from '../ProfileCard/T2ProfileCardContainer';
import { UnstyledTooltip } from '../Tooltip/UnstyledTooltip';
import { PROFILE_CARD_HOVER_DELAY } from '../ProfileCard/constants';
import { NUMBER_OF_PROFILE_IMAGES } from './constants';
import { TAvatarSize } from '../UserProfileImage/types';
import { useTerritoryDetailsModalContext } from '../TerritoryDetails/context/TerritoryDetailsModalContextProvider';
import { Stack } from '../Stack/Stack';
import { TTerritoryDetailsPlacement } from '../TerritoryDetails/types';

type TTerritoryMembersProps = {
  members: TMember[];
  membersCount: number;
  territoryName?: string;
  interactionsCopyClassname?: string;
  avatarClassname?: string;
  isVisible?: boolean;
  profileImageSize?: TAvatarSize;
  numberOfProfileImages?: number;
  hideMembersCopy?: boolean;
  placement: TTerritoryDetailsPlacement;
};

const TerritoryMembers: FC<TTerritoryMembersProps> = ({
  members = [],
  membersCount,
  interactionsCopyClassname,
  avatarClassname,
  isVisible,
  profileImageSize = 'medium',
  numberOfProfileImages = NUMBER_OF_PROFILE_IMAGES,
  hideMembersCopy = false,
  placement = 'sidebar',
}) => {
  const { handleOpenTerritoryMembersModal } = useTerritoryDetailsModalContext();
  const uniqueMembers = uniqBy(members, 'id');
  const profileImagesArray = uniqueMembers?.slice(0, numberOfProfileImages).filter(Boolean);
  const actualMembersCount = membersCount - (members.length - uniqueMembers.length);
  const shouldDisplayModal = profileImagesArray.length < actualMembersCount;

  const items = profileImagesArray.map(({ id, username, profileImage }) => {
    const userProfileUrl = getUserPageUrl(username as string);
    return {
      key: id,
      content: (
        <StackAvatar>
          <UnstyledTooltip
            direction="bottom"
            floatingUIHover={PROFILE_CARD_HOVER_DELAY}
            trigger={
              <div className="flex items-center justify-center">
                <Link
                  className="absolute inset-0 w-full h-full z-20"
                  href={userProfileUrl}
                  target="_blank"
                  rel={getLinkRelAttributeValue(userProfileUrl)}
                >
                  {' '}
                </Link>
                <UserProfileImage
                  profileImage={profileImage as TCloudinaryImageShape}
                  size={profileImageSize}
                  className={avatarClassname}
                  isVisible={isVisible}
                />
              </div>
            }
          >
            <T2ProfileCardContainer id={id as string} />
          </UnstyledTooltip>
        </StackAvatar>
      ),
    };
  });

  return (
    <div className="list-none flex flex-row items-center justify-start">
      <Stack items={items} />
      {actualMembersCount > 0 && shouldDisplayModal && (
        <button
          className="ml-2 flex gap-x-1 items-center justify-center group"
          onClick={handleOpenTerritoryMembersModal}
        >
          <ArticleInteractionsCopy
            className={cx('text-white-100', interactionsCopyClassname, {
              '!text-sm': placement === 'territoryDiscovery',
            })}
          >
            +{actualMembersCount - profileImagesArray.length}
          </ArticleInteractionsCopy>
          {!!profileImagesArray.length && !hideMembersCopy && (
            <ArticleInteractionsCopy
              className={
                (cx('hidden md:block text-white-100 whitespace-nowrap', interactionsCopyClassname),
                {
                  '!text-sm': placement === 'territoryDiscovery',
                })
              }
            >
              members
            </ArticleInteractionsCopy>
          )}
        </button>
      )}
    </div>
  );
};
export default TerritoryMembers;
