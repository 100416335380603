import { getDateWeekAgo } from 'utils/getDateWeekAgo';
import Decimal from 'decimal.js';
import cx from 'classnames';
import {
  type TGetTimePointsFromTransactionLogResponse,
  getTimePointsFromTransactionLog as queryGetTimePointsFromTransactionLog,
} from 'src/queries/getTimePointsFromTransactionLog';
import { calculatePercentageGrowth } from 'utils/calculatePercentageGrowth';
import Label from '../Label/Label';
import { useT2Query } from '../../hooks/useT2Query';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';

type TTerritoryTimePointsChangeIndicatorProps = {
  territoryId: string;
  totalTerritoryTimePoints: number;
  className?: string;
};

const dateWeekAgo = getDateWeekAgo();

export const TerritoryTimePointsChangeIndicator = ({
  territoryId,
  totalTerritoryTimePoints,
  className,
}: TTerritoryTimePointsChangeIndicatorProps) => {
  const isTerritoryPageV2 = getFeatureFlag('territoryPageV2');

  const totalTerritoryTimePointsDecimal = new Decimal(totalTerritoryTimePoints);

  const { data, loading, error } = useT2Query<TGetTimePointsFromTransactionLogResponse>(
    queryGetTimePointsFromTransactionLog,
    {
      variables: { territoryId, fromDate: dateWeekAgo },
    },
  );

  if (loading || error) {
    return null;
  }

  const timePointsFromLastWeekDecimal = new Decimal(data?.getTimePointsFromTransactionLog || 0);

  const timePointsLastWeekPercentageGrowth = calculatePercentageGrowth(
    totalTerritoryTimePointsDecimal,
    timePointsFromLastWeekDecimal,
  );

  if (!territoryId || totalTerritoryTimePointsDecimal.isNaN() || totalTerritoryTimePointsDecimal.isZero()) {
    return null;
  }

  if (timePointsLastWeekPercentageGrowth < 0.1) {
    return <div className={cx(className, 'h-[30px]')} />;
  }

  return (
    <Label
      className={cx(className, 'text-t2-success-darkGreen antialiased', {
        '!h-5 !py-0 !px-2 !text-[10px] !leading-none border border-t2-neutral-grey03': isTerritoryPageV2,
      })}
      backgroundColor={isTerritoryPageV2 ? 'ghost' : 'lightGreen'}
    >
      +{timePointsLastWeekPercentageGrowth}%
    </Label>
  );
};
