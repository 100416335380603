import React, { FC } from 'react';
import cx from 'classnames';

type TDiamondIconProps = {
  className?: string;
};

export const DiamondIcon: FC<TDiamondIconProps> = ({ className }) => (
  <svg
    className={cx(className)}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.26562 12.8516C6.03125 11.5078 5.07812 9.82031 3.26562 8.46094C2.375 7.78906 1.46875 7.35156 0.578125 7.16406V6.50781C2.34375 6.08594 4.07812 4.89844 5.1875 3.28906C5.75 2.47656 6.10937 1.67969 6.26562 0.851562H6.92188C7.1875 2.42969 8.40625 4.17969 10.0625 5.35156C10.875 5.92969 11.7188 6.32031 12.5781 6.50781V7.16406C10.8438 7.52344 8.82813 9.07031 7.82813 10.6484C7.32813 11.4453 7.03125 12.1797 6.92188 12.8516H6.26562Z"
      fill=""
      // fill="#242424"
    />
  </svg>
);
