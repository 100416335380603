import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ReadMore } from './ReadMore';
import { useOnWindowResize } from '../CloudinaryResponsiveImage/hooks';
import { TERRITORY_MAX_LINE_CLAMP } from './constants';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { TReadMorePlacement } from './types';

type TReadMoreContainerProps = {
  children: ReactNode;
  placement?: TReadMorePlacement;
  onClick?: () => void;
};

export const ReadMoreContainer: FC<TReadMoreContainerProps> = ({ children, placement, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { sendEvent } = useAnalytics();

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    sendEvent('territory_details_read_more_button_click');
  };

  const handleOnClick = () => (onClick ? onClick() : toggleExpanded());

  const updateLayout = () => {
    if (textRef.current && containerRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
      const maxHeight = lineHeight * TERRITORY_MAX_LINE_CLAMP;
      setShowButton(textRef.current.scrollHeight > maxHeight);

      containerRef.current.style.maxHeight = isExpanded ? `${textRef.current.scrollHeight}px` : `${maxHeight}px`;
    }
  };

  useEffect(updateLayout, [children, TERRITORY_MAX_LINE_CLAMP, isExpanded]);

  useOnWindowResize(updateLayout);

  return (
    <ReadMore
      containerRef={containerRef}
      textRef={textRef}
      isExpanded={isExpanded}
      showButton={showButton}
      onClick={handleOnClick}
      placement={placement}
    >
      {children}
    </ReadMore>
  );
};
