import React, { FC } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import Tooltip from '../Tooltip/Tooltip';
import InformationIcon from '../Icons/InformationIcon';
import { colors } from '../Spinner/utils/colors';
import { DiamondIcon } from '../Icons/DiamondIcon';
import { formatLargeNumber } from '../../../utils/formatLargeNumber';
import { TerritoryTimePointsChangeIndicator } from '../Territory/TerritoryTimePointsChangeIndicator';
import { TTerritoryDetailsPlacement } from './types';

type TTerritoryDetailsTotalTimepointsProps = {
  totalTerritoryTimePoints: number;
  territoryId: string;
  placement?: TTerritoryDetailsPlacement;
  showIcon?: boolean;
  showTooltip?: boolean;
};

export const TerritoryDetailsTotalTimepoints: FC<TTerritoryDetailsTotalTimepointsProps> = ({
  territoryId,
  totalTerritoryTimePoints,
  placement = 'sidebar',
  showIcon = true,
  showTooltip = true,
}) => (
  <div
    className={cx('flex flex-col gap-1', {
      'items-center': placement === 'territoryDiscovery',
    })}
  >
    <div className={cx('flex items-center gap-2', { 'order-2': placement === 'territoryDiscovery' })}>
      <Typo variant="small-p" className="!text-xs text-t2-neutral-grey06 whitespace-nowrap">
        Total t.p.
      </Typo>
      {showTooltip && (
        <Tooltip
          direction="bottom"
          trigger={
            <div className="w-3 h-3">
              <InformationIcon height="12" width="12" color={cx([`${colors?.t2.neutral.grey05}`])} />
            </div>
          }
        >
          <Typo
            variant="p2"
            text="Time Points (t.p.) reflect the aggregated activity from reading and writing in this Territory. Percentage indicates weekly increase relative to previous week."
            className="!text-sm whitespace-normal font-light"
          />
        </Tooltip>
      )}
    </div>

    <div className={cx('flex h-7 items-center gap-2', { 'order-1': placement === 'territoryDiscovery' })}>
      <div className="flex items-center gap-2">
        {showIcon && <DiamondIcon className="fill-t2-neutral-grey08" />}
        <Typo variant="small-p" className={cx('antialiased', {})}>
          {formatLargeNumber(totalTerritoryTimePoints, 3) || 0}
        </Typo>
      </div>
      <div className="hidden lg:block">
        <TerritoryTimePointsChangeIndicator
          territoryId={territoryId}
          totalTerritoryTimePoints={totalTerritoryTimePoints}
        />
      </div>
    </div>
  </div>
);
