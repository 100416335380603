import React, { FC } from 'react';
import { Territory as TTerritory } from 't2-graphql-types';
import Typo from '../Typography/Typo';
import TerritoryMembers from '../TerritoryHeader/TerritoryMembers';
import { NUMBER_OF_PROFILE_IMAGES_TERRITORY_V2 } from '../TerritoryHeader/constants';
import { TMember } from '../TerritoryMembersPopUp/MemberProfile';
import { TTerritoryDetailsPlacement } from './types';

type TTerritoryDetailsMembersProps = {
  territory: TTerritory;
  territoryMembers: TMember[];
  territoryMembersCount: number;
  placement?: TTerritoryDetailsPlacement;
  hideMembersCopy?: boolean;
};

export const TerritoryDetailsMembers: FC<TTerritoryDetailsMembersProps> = ({
  territory,
  territoryMembers,
  territoryMembersCount,
  placement = 'sidebar',
  hideMembersCopy = false,
}) => (
  <div className="flex flex-col gap-1">
    {placement === 'sidebar' && (
      <Typo variant="small-p" className="!text-xs text-t2-neutral-grey06">
        Members
      </Typo>
    )}
    <TerritoryMembers
      members={territoryMembers}
      membersCount={territoryMembersCount}
      territoryName={territory?.name || ''}
      profileImageSize="small"
      interactionsCopyClassname="!text-t2-neutral-grey09 group-hover:!text-t2-primary-brandColor"
      numberOfProfileImages={NUMBER_OF_PROFILE_IMAGES_TERRITORY_V2}
      hideMembersCopy={hideMembersCopy}
      placement={placement}
    />
  </div>
);
