import { gql } from '@apollo/client';
import Decimal from 'decimal.js';

export const getTimePointsFromTransactionLog = gql`
  query getTimePointsFromTransactionLog(
    $userId: String
    $territoryId: String
    $fromDate: DateTime
    $untilDate: DateTime
  ) {
    getTimePointsFromTransactionLog(
      userId: $userId
      territoryId: $territoryId
      fromDate: $fromDate
      untilDate: $untilDate
    )
  }
`;

export type TGetTimePointsFromTransactionLogResponse = {
  getTimePointsFromTransactionLog: Decimal;
};
