import Decimal from 'decimal.js';

export const calculatePercentageGrowth = (totalValue: Decimal, newValue: Decimal) => {
  if (
    totalValue.isNaN() ||
    newValue.isNaN() ||
    (totalValue.isZero() && newValue.isZero()) ||
    newValue.greaterThan(totalValue) ||
    totalValue.lessThan(0) ||
    newValue.lessThan(0)
  ) {
    return 0;
  }

  const totalValueBeforeNewValue = totalValue.minus(newValue);

  if (totalValueBeforeNewValue.equals(0)) {
    return 100;
  }

  return newValue.div(totalValueBeforeNewValue).times(100).toDecimalPlaces(2, Decimal.ROUND_DOWN).toNumber();
};
